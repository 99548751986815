<template>
  <v-form v-model="valid">
    <v-container>
      <div>
        <p class="main-title">Last, provide some admin information</p>
        <p>
          This is for the Clutch team to contact you, promote your great reviews
          on social media, and give you helpful stats. None of this info will be
          displayed on your profile, this is for admin use only.
        </p>
      </div>
      <v-row>
        <v-col cols="12">
          <label class="mb-3">Admin Email</label>
          <v-text-field
            outlined
            v-validate="'email'"
            dense
            name="admin_email"
            hide-details
            placeholder="Admin Email"
            v-model="admin_info.admin_email"
            prepend-inner-icon="mdi-email-outline"
            @keyup="submitData"
          ></v-text-field>
          <span v-show="errors.has('admin_email')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <label class="mb-3">Admin Phone Number</label>
          <v-text-field
            outlined
            v-validate="'numeric'"
            dense
            name="admin_phone_number"
            hide-details
            placeholder="Admin Phone Number"
            v-model="admin_info.admin_phone_number"
            prepend-inner-icon="mdi-phone"
            @keyup="submitData"
          ></v-text-field>
          <span v-show="errors.has('admin_phone_number')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <label class="mb-3">LinkedIn Profile URL</label>
          <v-text-field
            outlined
            dense
            hide-details
            placeholder="LinkedIn Profile URL"
            prepend-inner-icon="mdi-linkedin"
            v-model="admin_info.linked_in_url"
            @keyup="submitData"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <label class="mb-3">Facebook URL</label>
          <v-text-field
            outlined
            dense
            hide-details
            placeholder="Facebook URL"
            prepend-inner-icon="mdi-facebook"
            v-model="admin_info.facebook_url"
            @keyup="submitData"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label class="mb-3">Twitter URL</label>
          <v-text-field
            outlined
            dense
            placeholder="Twitter URL"
            prepend-inner-icon="mdi-twitter"
            v-model="admin_info.twitter_url"
            @keyup="submitData"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: ["companydata"],
  name: "ComoanyStep5",

  data: function () {
    return {
      isSelecting: false,
      valid: false,

      items: ["Foo", "Bar", "Fizz", "Buzz"],

      admin_info: {
        admin_email: "",
        admin_phone_number: "",
        linked_in_url: "",
        facebook_url: "",
        twitter_url: "",
      },
    };
  },
  updated() {
    setTimeout(() => {
      if (this.companydata) {
        this.admin_info = this.companydata;
        this.$emit("info", { data: this.admin_info, type: "admin_info" });
      }
    }, 5000);
  },
  methods: {
    submitData() {
      this.$emit("info", { data: this.admin_info, type: "admin_info" });
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];

      // do something
    },
  },
};
</script>
