<template>
  <v-form v-model="valid">
    <v-container>
      <div>
        <p class="main-title">Show off your best work</p>
      </div>
      <div>
        <p class="main-sub-title">Add portfolio items</p>
        <p>
          Your portfolio items will display on your profile in the order below.
          To reorder your portfolio items, drag and drop each item into the
          correct position.
        </p>
      </div>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title> New Portfolio Item </v-card-title>
            <v-card-text>
              <v-row v-if="!previewImage">
                <v-col>
                  <v-btn
                    color="primary"
                    :loading="isSelecting"
                    @click="onButtonClick"
                    class="text-none add-logo"
                    depressed
                  >
                    <v-icon left> mdi-cloud-download </v-icon>

                    Add Image
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    @change="onFileChanged"
                    type="file"
                    accept="image/*"
                  />
                </v-col>
                <!-- <p class="mt-4">OR</p> -->
                <p v-if="message" style="color: red">{{ message }}</p>
              </v-row>
              <v-row v-if="previewImage"><img :src="previewImage" /> </v-row>
              <v-row v-if="previewImage"
                ><p class="pimg" @click="removeImage">X Remove Image</p></v-row
              >

              <!-- <v-row v-if="!previewImage">
                <v-col>
                  <label class="mb-3 company-step-5">Video URL</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    placeholder="Video URL"
                    suffix="ADD"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col>
                  <label class="mb-3 company-step-5">Title</label>
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    v-model="title"
                    placeholder="Title this work"
                  ></v-text-field>
                </v-col>
                <p v-if="message" style="color: red">{{ message }}</p>
              </v-row>

              <v-row>
                <v-col>
                  <label class="mb-3 company-step-5">Project Category</label>
                  <v-select
                    outlined
                    dense
                    hide-details
                    :items="Company_List"
                    item-text="name"
                    item-value="id"
                    v-model="project_category"
                    placeholder="What Project Category Your project have?"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label class="mb-3 company-step-5">Project Size</label>
                  <v-select
                    outlined
                    dense
                    hide-details
                    :items="min_pro_size"
                    v-model="min_pro"
                    placeholder="What Project size Your project have?"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <label class="mb-3 company-step-5">Description</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="description"
                    :counter="100"
                    placeholder="Description"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-col class="text-right">
            <b-button
              variant="outline-dark"
              class="button-custom-blck-company font-size-14 left"
              @click="addPortfolio"
              >Add</b-button
            >
          </v-col>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-for="(items, index) in portfoilio"
              :key="index"
            >
              <img :src="items.image" style="height: 170px; width: 100%" />
              <p class="pimg" @click="removeItems(index)">X Remove Item</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["companyportfolio"],
  name: "ComoanyStep5",

  data: function () {
    return {
      isSelecting: false,
      valid: false,
      project_category: null,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      description: null,
      previewImage: null,
      min_pro: null,
      portfoilio: [],
      title: null,
      selectedFile: null,
      message: null,
      Company_List: [],
      min_pro_size: [],
      portfolioid: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.companyportfolio && this.companyportfolio.length > 0) {
        this.companyportfolio.forEach((res) => {
          let port = {
            portfolioid: res.id,
            imageData: res.image,
            image:
              process.env.VUE_APP_URL + "public/images/protfoilio/" + res.image,
            imgbinary: this.selectedFile,
            project_category: res.project_category,
            description: res.discription,
            title: res.title,
            project_size: res.project_size,
          };
          console.log(port);
          this.portfoilio.push(port);
          console.log(this.portfoilio);
        });
      }
    }, 5000);
  },

  created() {
    this.fetchcompany();
  },

  methods: {
    ...mapActions([
      "getCompanyCategory",
      "fetchMinSizeProject",
      "removeCompanyPortfolio",
      "addCompanyPortfolio",
    ]),
    fetchcompany() {
      this.fetchMinSizeProject().then((response) => {
        response.data.data.forEach((value) => {
          this.min_pro_size.push(value.name);
        });
      });
      this.getCompanyCategory().then((response) => {
        response.data.data.forEach((value) => {
          if (value["parent_id"] == 0) {
            let s_id = value["id"];

            response.data.data.forEach((v) => {
              if (v["parent_id"] == s_id) {
                let dic = {};
                dic["name"] = v["name"];
                dic["id"] = v["id"];
                this.Company_List.push(dic);
              }
            });
          }
        });
      });
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
      // do something
    },
    removeImage() {
      this.previewImage = "";
    },
    addPortfolio() {
      if (this.previewImage != null && this.title != "") {
        let port = {
          portfolioid: null,
          image: this.previewImage,
          imgbinary: this.selectedFile,
          project_category: this.project_category,
          description: this.description,
          title: this.title,
          project_size: this.min_pro,
        };
        this.portfoilio.push(port);
        this.addCompanyPortfolio(port).then((response) => {
          console.log(response);
        });
        // write emit code here
        this.imagebinary = "";
        this.previewImage = "";
        this.project_category = "";
        this.description = "";
        this.title = "";
        this.min_pro = "";
      } else {
        this.message = "This field is required.";
        setTimeout(() => {
          this.message = "";
        }, 5000);
      }

      this.$emit("info", { data: this.portfoilio, type: "portfolio_info" });
    },
    removeItems(index) {
      this.removeCompanyPortfolio(this.portfoilio).then((response) => {
        console.log(response);
        this.portfoilio.splice(index, 1);
      });

      this.$emit("info", { data: this.portfoilio, type: "portfolio_info" });
    },
  },
};
</script>
<style scoped>
.pimg {
  cursor: pointer;
  color: red;
}
.display-flex {
  display: inline-flex;
}
</style>
