<template>
  <v-form v-model="valid">
    <v-container>
      <div>
        <p class="main-title">How do you want your leads to contact you?</p>
      </div>
      <v-row>
        <v-col>
          <label class="mb-3">Company Website</label>
          <v-text-field
            v-validate="'required'"
            outlined
            dense
            hide-details
            name="website"
            v-model="leads_info.website"
            prepend-inner-icon="mdi-web"
            placeholder="Company Website"
            @keyup="submitData"
          ></v-text-field>
          <span v-show="errors.has('website')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label class="mb-3">Primary Sales Email</label>
          <v-text-field
            v-validate="'email'"
            outlined
            name="primary_email"
            dense
            hide-details
            v-model="leads_info.primary_email"
            prepend-inner-icon="mdi-email"
            placeholder="Primary Sales Email"
            @keyup="submitData"
          ></v-text-field>
          <span v-show="errors.has('primary_email')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: ["companydata"],
  name: "ComoanyStep3",

  data: function () {
    return {
      // slug : localStorage.getItem("slug"),
      valid: null,
      leads_info: {
        slug: localStorage.getItem("slug"),
        website: null,
        primary_email: null,
      },
    };
  },
  updated() {
    setTimeout(() => {
      if (this.companydata) {
        console.log(this.companydata)
        this.leads_info = this.companydata;
        this.$emit("info", { data: this.leads_info, type: "leads_info" });
      }
    }, 5000);
  },
  methods: {
    submitData() {
      this.$emit("info", { data: this.leads_info, type: "leads_info" });
    },
  },
};
</script>
