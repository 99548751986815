<template>
  <v-form v-model="valid">
    <v-container>
      <div>
        <p class="main-title">Let's get some basic information</p>
      </div>
      <v-row>
        <v-col cols="12" md="2">
          <v-btn
            v-if="!previewImage"
            color="primary"
            :loading="isSelecting"
            @click="onButtonClick"
            class="text-none add-logo"
            depressed
          >
            <v-icon left> mdi-cloud-download </v-icon>
            Add Logo
          </v-btn>
          <v-btn
            color="primary"
            :loading="isSelecting"
            @click="onButtonClick"
            v-if="previewImage"
            class="text-none add-logo"
            v-bind:style="{ backgroundImage: 'url(' + previewImage + ')' }"
            depressed
          >
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            name="logo"
            @change="onFileChanged"
            type="file"
            accept="image/*"
          />
        </v-col>

        <v-col cols="12" md="10">
          <v-text-field
            name="company_name"
            v-model="basic_info.company_name"
            v-validate="'required'"
            placeholder="Company Name"
            required
            @change="submitData"
          ></v-text-field>
          <span v-show="errors.has('company_name')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <label class="mb-3">What is your minimum project size?</label>
          <v-select
            v-validate="'required'"
            name="min_project_size"
            outlined
            dense
            hide-details
            v-model="basic_info.min_project_size"
            :items="min_pro_size"
            @change="submitData"
            placeholder="What is your minimum project size?"
          ></v-select>
          <span v-show="errors.has('min_project_size')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>

        <v-col cols="12" md="6">
          <label class="mb-3">What is your hourly rate?</label>
          <v-select
            v-validate="'required'"
            name="hourly_rate"
            outlined
            dense
            hide-details
            v-model="basic_info.hourly_rate"
            :items="hourly_rat"
            @change="submitData"
            placeholder="What is your hourly rate?"
          ></v-select>
          <span v-show="errors.has('hourly_rate')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <label class="mb-3">What size is your company?</label>
          <v-select
            v-validate="'required'"
            name="company_size"
            outlined
            hide-details
            dense
            :items="company_si"
            v-model="basic_info.company_size"
            @change="submitData"
            placeholder="What size is your company?"
          ></v-select>
          <span v-show="errors.has('company_size')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>

        <v-col cols="12" md="6">
          <label class="mb-3">When was your company founded?</label>
          <v-text-field
            v-validate="'required|max_value:2021'"
            name="founded_year"
          
            outlined
            dense
            hide-details
            v-model="basic_info.founded_year"
            @change="submitData"
            placeholder="When was your company founded?"
          ></v-text-field>
          <span v-show="errors.has('founded_year')" class="help is-danger"
            >This field is required.</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label class="mb-3">Tagline</label>
          <v-text-field
            outlined
            dense
            hide-details
            v-model="basic_info.tag_line"
            :counter="50"
            @change="submitData"
            placeholder="Tagline"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label class="mb-3">Summary</label>
          <v-textarea
            outlined
            v-model="basic_info.summary"
            :counter="100"
            placeholder="Summary"
            @change="submitData"
            required
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["companydata"],
  name: "ComoanyStep1",

  data: function () {
    return {
      basic_info: {
        company_logo: null,
        company_name: null,
        min_project_size: null,
        hourly_rate: null,
        company_size: null,
        founded_year: null,
        tag_line: null,
        summary: null,
      },
      min_pro_size: [],
      hourly_rat: [],
      company_si: [],
      previewImage: null,
      valid: false,
      selectedFile: null,
      isSelecting: false,
    };
  },
  updated() {
    setTimeout(() => {
      if (this.companydata) {
        this.previewImage =
          process.env.VUE_APP_URL +
          "public/images/company/" +
          this.companydata.company_logo;
        this.basic_info = this.companydata;
        if (this.selectedFile) {
          this.basic_info.company_logo = this.selectedFile;
        }
        this.$emit("info", { data: this.basic_info, type: "basic_info" });
      }
      //
    }, 3000);
  },
  created() {
    this.fetchMinPro();
  },
  methods: {
    ...mapActions([
      "fetchMinSizeProject",
      "fetchHourlyRate",
      "fetchCompanySize",
    ]),
    fetchMinPro() {
      this.fetchMinSizeProject().then((response) => {
        response.data.data.forEach((value) => {
          this.min_pro_size.push(value.name);
        });
      });
      this.fetchHourlyRate().then((response) => {
        response.data.data.forEach((value) => {
          this.hourly_rat.push(value.name);
        });
      });
      this.fetchCompanySize().then((response) => {
        response.data.data.forEach((value) => {
          this.company_si.push(value.name);
        });
      });
    },
    submitData() {
      this.$emit("info", { data: this.basic_info, type: "basic_info" });
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.basic_info.company_logo = e.target.files[0];
      this.selectedFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };

      // do something
    },
  },
};
</script>
<style scoped>
.add-logo {
  height: 120px !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  border-radius: 10px;
  background-size: 100% 100%;
  width: 100%;
}
</style>