<template>
  <v-form v-model="valid">
    <v-container>
      <div>
        <p class="main-title">Where is your company located?</p>
      </div>
      <v-card class="main-card">
        <v-row>
          <v-col>
            <label class="mb-3">Search Company Address</label>
            <v-text-field
              v-validate="'required'"
              outlined
              dense
              name="address"
              hide-details
              :value="address"
              for="autoComplete"
              id="autoComplete"
              ref="addressInput"
              placeholder="Search Company Address"
            ></v-text-field>
            <span v-show="errors.has('address')" class="help is-danger"
              >This field is required.</span
            >
          </v-col>
        </v-row>
        <v-row v-if="showaddress && country" class="ml-0">
          <v-col cols="12" md="4" class="font-bold">Country</v-col>
          <v-col cols="12" md="6">{{ country }}</v-col>
          <hr />
        </v-row>

        <v-row v-if="showaddress && states" class="m-0">
          <v-col cols="12" md="4" class="font-bold">State/Province</v-col>
          <v-col cols="12" md="6">{{ states }}</v-col>
          <hr class="m-0" />
        </v-row>
        <v-row v-if="showaddress && city" class="m-0">
          <v-col cols="12" md="4" class="font-bold">City </v-col>
          <v-col cols="12" md="6">{{ city }}</v-col>
          <hr class="m-0" />
        </v-row>
        <v-row v-if="showaddress && zipcode" class="m-0">
          <v-col cols="12" md="4" class="font-bold">ZIP/Postal Code</v-col>
          <v-col cols="12" md="6">{{ zipcode }}</v-col>
          <hr class="m-0" />
        </v-row>
        <v-row v-if="showaddress && address" class="m-0">
          <v-col cols="12" md="4" class="font-bold">Address</v-col>
          <v-col cols="12" md="6">{{ address }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <label class="mb-3">Phone Number</label>
            <v-text-field
              v-validate="'required'"
              outlined
              dense
              hide-details
              name="phone_number"
              v-model="phone_number"
              placeholder="Phone Number"
              prepend-inner-icon="mdi-phone"
              @keyup="submitData"
            ></v-text-field>
            <span v-show="errors.has('phone_number')" class="help is-danger"
              >This field is required.</span
            >
          </v-col>

          <v-col cols="12" md="6">
            <label class="mb-3">Employee Count</label>
            <v-select
              v-validate="'required'"
              outlined
              dense
              hide-details
              name="employee_count"
              v-model="employee_count"
              :items="employee"
              placeholder="Employee Count"
              required
              @change="submitData"
            ></v-select>
            <span v-show="errors.has('employee_count')" class="help is-danger"
              >This field is required.</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["companyaddress"],
  name: "ComoanyStep2",

  data: function () {
    return {
      showaddress: false,
      country: null,
      employee: [],
      city: null,
      states: null,
      zipcode: null,
      address: null,
      phone_number: null,
      valid: false,
      employee_count: "",
      hourly_rate: "",

      location_info: {
        slug: null,
        country: null,
        city: null,
        zip_code: null,
        address: null,
        phone_number: null,
        employee_count: null,
      },
    };
  },
  created() {
    this.fetchEmployeecount();
  },
  //  updated() {
  //   setTimeout(() => {
  //      if (this.companyaddress) {
  //       console.log(this.companyaddress)
  //       let locationData = this.companyaddress.slice(-1)[0];
  //       if (locationData) {
  //         this.showaddress = true;
  //         this.location_info.country = this.country = locationData.country;
  //         this.location_info.city = this.city = locationData.city;
  //         this.location_info.states = this.states = locationData.states;
  //         this.location_info.zipcode = this.zipcode = locationData.zip_code;
  //         this.location_info.address = this.address = locationData.address;
  //         this.location_info.phone_number = this.phone_number =
  //           locationData.phone_number;
  //         this.location_info.employee_count = this.employee_count =
  //           locationData.employee_count;
  //         //this.hourly_rate = locationData;
  //         this.$emit("info", {
  //           data: this.location_info,
  //           type: "location_info",
  //         });
  //       }
  //     }
   
  //   }, 3000);
  // },
  methods: {
    ...mapActions(["fetchEmployeeCount"]),
    fetchEmployeecount() {
      this.fetchEmployeeCount().then((response) => {
        response.data.data.forEach((value) => {
          this.employee.push(value.name);
        });
      });
    },
    submitData() {
      this.location_info.phone_number = this.phone_number;
      this.location_info.employee_count = this.employee_count;
      this.$emit("info", { data: this.location_info, type: "location_info" });
    },
  },
  async mounted() {
    setTimeout(() => {
      if (this.companyaddress) {
        console.log(this.companyaddress)
        let locationData = this.companyaddress.slice(-1)[0];
        if (locationData) {
          this.showaddress = true;
          this.location_info.country = this.country = locationData.country;
          this.location_info.city = this.city = locationData.city;
          this.location_info.states = this.states = locationData.states;
          this.location_info.zipcode = this.zipcode = locationData.zip_code;
          this.location_info.address = this.address = locationData.address;
          this.location_info.phone_number = this.phone_number =
            locationData.phone_number;
          this.location_info.employee_count = this.employee_count =
            locationData.employee_count;
          //this.hourly_rate = locationData;
          this.$emit("info", {
            data: this.location_info,
            type: "location_info",
          });
        }
      }
    }, 5000);
    this.$loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB7cXNxkeoI-_I82XgYKccHC9D4e3nlUhs&libraries=places"
    ).then(() => {
      const options = {
        fields: [
          "address_components",
          "formatted_address",
          "geometry",
          "icon",
          "name",
        ],
      };

      const input = document.getElementById("autoComplete");
      this.autocomplete = new window.google.maps.places.Autocomplete(
        input,
        options
      );

      this.autocomplete.addListener("place_changed", () => {
        (async () => {
          this.showaddress = true;
          const place = this.autocomplete.getPlace();
          this.$emit("address-change", place.formatted_address);
          this.address = place.formatted_address;
          this.city = place.address_components.find(
            (component) => component.types[0] === "locality"
          )
            ? place.address_components.find(
                (component) => component.types[0] === "locality"
              ).long_name
            : "";
          this.zipcode = place.address_components.find(
            (component) => component.types[0] === "postal_code"
          )
            ? place.address_components.find(
                (component) => component.types[0] === "postal_code"
              ).long_name
            : "";
          this.states = place.address_components.find(
            (component) => component.types[0] === "administrative_area_level_1"
          )
            ? place.address_components.find(
                (component) =>
                  component.types[0] === "administrative_area_level_1"
              ).long_name
            : "";

          this.country = place.address_components.find(
            (component) => component.types[0] === "country"
          )
            ? place.address_components.find(
                (component) => component.types[0] === "country"
              ).long_name
            : "";
          this.location_info.slug = localStorage.getItem("slug");
          this.location_info.country = this.country;
          this.location_info.city = this.city;
          this.location_info.zip_code = this.zipcode;
          this.location_info.address = place.formatted_address;
          this.location_info.phone_number = this.phone_number;
          this.location_info.employee_count = this.employee_count;
          this.$emit("info", {
            data: this.location_info,
            type: "location_info",
          });
        })();
      });
    });
  },
  async beforeDestroy() {
    this.$unloadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB7cXNxkeoI-_I82XgYKccHC9D4e3nlUhs"
    )
      .then(() => {
        // Script was unloaded successfully
      })
      .catch(() => {
        // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
      });
  },
};
</script>
<style scoped>
.main-card {
  padding: 30px;
}
.font-bold {
  font-weight: 600;
}
</style>