<template>
  <v-form v-model="valid">
    <v-container>
      <v-row class="mr-0 ml-0 txt-brown">
        <h2>What's your company's focus</h2></v-row
      >
      <div class="text-center">
        <b-spinner v-if="loading"></b-spinner>
      </div>

      <v-row class="mr-0 ml-0 mb-3 pt-5">
        <v-col cols="12" sm="5" class="focas-side1 pt-0">
          <v-row>
            <!-- <v-col cols="12" sm="1" class="vertical-line pr-0 pl-0 text-center">
              <b-img
                src="image/sugar-cube.png"
                fluid
                alt="Responsive image"
              ></b-img>
            </v-col> -->
            <v-col cols="12" sm="11">
              <h4>Add Service Line</h4>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                solo
                label="Append"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
          <div class="service">
            <v-row class="mt-0" v-for="(items, index) in catlist" :key="index">
              <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
              <v-col cols="12" sm="10" class="pt-0">
                <v-row>
                  <v-col cols="12" sm="10"
                    >{{ index }}
                    <div v-if="isHidden && index == shows">
                      <v-row v-for="(sitem, sindex) in items" :key="sindex">
                        <v-col cols="12" sm="10">
                          {{ sindex }}
                          <div v-if="isHidden1 && sindex == shows1">
                            <v-row v-for="(ssitem, keys) in sitem" :key="keys">
                              <v-col cols="12" sm="10"> {{ keys }} </v-col>
                              <v-col cols="12" sm="2">
                                <v-btn class="client">
                                  <v-btn
                                    class="client"
                                    @click="addCategoryItems(keys)"
                                  >
                                    <i
                                      class="mdi mdi-plus"
                                      v-if="!CategoryItems.includes(keys)"
                                    ></i>
                                    <i
                                      class="mdi mdi-minus"
                                      v-if="CategoryItems.includes(keys)"
                                    ></i>
                                  </v-btn> </v-btn
                              ></v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          v-if="Object.keys(sitem).length !== 0"
                        >
                          <v-btn class="client">
                            <a @click="getshow1(sindex)"
                              ><i class="mdi mdi-chevron-down"></i></a
                          ></v-btn>
                        </v-col>
                        <v-col cols="12" sm="2" v-else>
                          <v-btn
                            class="client"
                            @click="addCategoryItems(sindex)"
                          >
                            <i
                              class="mdi mdi-plus"
                              v-if="!CategoryItems.includes(sindex)"
                            ></i>
                            <i
                              class="mdi mdi-minus"
                              v-if="CategoryItems.includes(sindex)"
                            ></i>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    class="text-right"
                    v-if="Object.keys(items).length !== 0"
                  >
                    <a @click="getshow(index)"
                      ><i class="mdi mdi-chevron-down"></i
                    ></a>
                  </v-col>
                  <v-col cols="12" sm="2" class="text-right" v-else>
                    <v-btn class="client" @click="addCategoryItems(index)">
                      <i
                        class="mdi mdi-plus"
                        v-if="!CategoryItems.includes(index)"
                      ></i>
                      <i
                        class="mdi mdi-minus"
                        v-if="CategoryItems.includes(index)"
                      ></i>
                    </v-btn>
                  </v-col>

                  <hr class="ml-3 line-width" />
                </v-row>
              </v-col>
              <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 ml-4 bt">
          <v-row>
            <v-col class="pt-0"><h4>Service Line</h4></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <strong>{{ CategoryItems.length }}</strong> Service Line
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <span :class="categoryTotalPercentage > 100 ? 'redclass' : ''"
                >Total = {{ categoryTotalPercentage }}%</span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="categoryTotalPercentage > 0 && categoryTotalPercentage < 100"
          >
            <v-col cols="12" sm="6">
              <span class="redclass">The Service Line Must Be 100%</span>
            </v-col>
          </v-row>
          <v-row v-if="categoryTotalPercentage > 100">
            <v-col cols="12" sm="6">
              <span class="redclass"
                >The Service Line won't be greater than 100%</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b-progress class="mt-2" max="100" show-value>
                <b-progress-bar
                  :value="categoryTotalPercentage"
                  :variant="
                    categoryTotalPercentage == 100
                      ? 'success'
                      : categoryTotalPercentage < 100
                      ? 'warning '
                      : 'danger '
                  "
                ></b-progress-bar>
              </b-progress>
            </v-col>
          </v-row>

          <div class="pt-4" v-if="CategoryItems.length > 0">
            <v-row v-for="catite in CategoryItems" :key="catite">
              <v-col cols="12" sm="3">
                <v-text-field
                  v-validate="'required'"
                  v-model="category[catite]"
                  name="category"
                  hide-details
                  @change="getCategoryPercentage(category[catite])"
                  outlined
                  dense
                  label=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" class="pl-0 pt-4 pr-0"> % </v-col>
              <v-col cols="12" sm="8" class="pl-0">
                {{ catite }}
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <span v-show="errors.has('category')" class="help is-danger"
        >This field is required.</span
      >
      <!-- Second Section -->
      <v-row class="mr-0 ml-0 mb-5 mt-10 pt-5">
        <v-col cols="12" sm="5" class="focas-side1 pt-0">
          <v-row>
            <v-col cols="12" sm="11">
              <h4>Add Industry Focus</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                solo
                label="Append"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
          <v-row class="mt-0" v-for="flist in focuslist" :key="flist.id">
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10" class="pt-0">
              <v-row>
                <v-col cols="12" sm="10">{{ flist }} </v-col>
                <v-col cols="12" sm="2" class="text-right"
                  ><v-btn class="client" @click="addIndustryFocusItems(flist)">
                    <i
                      class="mdi mdi-plus"
                      v-if="!industryFocusItems.includes(flist)"
                    ></i>
                    <i
                      class="mdi mdi-minus"
                      v-if="industryFocusItems.includes(flist)"
                    ></i></v-btn
                ></v-col>
                <hr class="ml-3 line-width" />
              </v-row>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 ml-4 bt">
          <v-row>
            <v-col class="pt-0"><h4>Industry Focus</h4></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <strong>{{ industryFocusItems.length }}</strong> Industry Focuses
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <span
                :class="industryFocusTotalPercentage > 100 ? 'redclass' : ''"
                >Total = {{ industryFocusTotalPercentage }}%</span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="
              industryFocusTotalPercentage > 0 &&
              industryFocusTotalPercentage < 100
            "
          >
            <v-col cols="12" sm="6">
              <span class="redclass">The Industry Focus Must Be 100%</span>
            </v-col>
          </v-row>
          <v-row v-if="industryFocusTotalPercentage > 100">
            <v-col cols="12" sm="6">
              <span class="redclass"
                >The Industry Focus won't be greater than 100%</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b-progress class="mt-2" max="100" show-value>
                <b-progress-bar
                  :value="industryFocusTotalPercentage"
                  :variant="
                    industryFocusTotalPercentage == 100
                      ? 'success'
                      : industryFocusTotalPercentage < 100
                      ? 'warning '
                      : 'danger '
                  "
                ></b-progress-bar>
              </b-progress>
            </v-col>
          </v-row>
          <div class="pt-4" v-if="industryFocusItems.length > 0">
            <v-row v-for="fite in industryFocusItems" :key="fite">
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="industryFocus[fite]"
                  hide-details
                  @change="getIndustryFocusPercentage(fite)"
                  outlined
                  dense
                  label=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" class="pl-0 pt-4 pr-0"> % </v-col>
              <v-col cols="12" sm="8" class="pl-0">
                {{ fite }}
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!-- Fourth Section  -->

      <v-row class="mr-0 ml-0 mb-5 mt-10 pt-5">
        <v-col cols="12" sm="5" class="focas-side1 pt-0">
          <v-row>
            <v-col cols="12" sm="11">
              <h4>Add Client Size</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                solo
                label="Append"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
          <v-row v-for="clist in clientlist" :key="clist.id" class="mt-0">
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10" class="pt-0">
              <v-row>
                <v-col cols="12" sm="10">{{ clist }} </v-col>
                <v-col cols="12" sm="2" class="text-right"
                  ><v-btn class="client" @click="addItems(clist)">
                    <i
                      class="mdi mdi-plus"
                      v-if="!clientItems.includes(clist)"
                    ></i>
                    <i
                      class="mdi mdi-minus"
                      v-if="clientItems.includes(clist)"
                    ></i></v-btn
                ></v-col>
                <hr class="ml-3 line-width" />
              </v-row>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 ml-4 bt">
          <v-row>
            <v-col class="pt-0"><h4>Client Size</h4></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <strong>{{ clientItems.length }}</strong> Client Size
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <span :class="clientTotalPercentage > 100 ? 'redclass' : ''"
                >Total = {{ clientTotalPercentage }}%</span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="clientTotalPercentage > 0 && clientTotalPercentage < 100"
          >
            <v-col cols="12" sm="6">
              <span class="redclass">The Client Size Must Be 100%</span>
            </v-col>
          </v-row>
          <v-row v-if="clientTotalPercentage > 100">
            <v-col cols="12" sm="6">
              <span class="redclass"
                >The Client Size won't be greater than 100%</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b-progress class="mt-2" max="100" show-value>
                <b-progress-bar
                  :value="clientTotalPercentage"
                  :variant="
                    clientTotalPercentage == 100
                      ? 'success'
                      : clientTotalPercentage < 100
                      ? 'warning '
                      : 'danger '
                  "
                ></b-progress-bar>
              </b-progress>
            </v-col>
          </v-row>
          <div class="pt-4" v-if="clientItems.length > 0">
            <v-row v-for="cite in clientItems" :key="cite">
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="city[cite]"
                  hide-details
                  @change="getPercentage(cite)"
                  outlined
                  dense
                  label=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" class="pl-0 pt-4 pr-0"> % </v-col>
              <v-col cols="12" sm="8" class="pl-0">
                {{ cite }}
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!-- Fifth Section -->

      <v-row class="mr-0 ml-0 mb-5 mt-10 pt-5">
        <v-col cols="12" sm="5" class="focas-side1 pt-0">
          <v-row>
            <v-col cols="12" sm="11">
              <h4>Select Specialazations</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                solo
                label="Append"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
          <v-row
            class="mt-0"
            v-for="spelist in specializationList"
            :key="spelist.id"
          >
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
            <v-col cols="12" sm="10" class="pt-0">
              <v-row>
                <v-col cols="12" sm="10">{{ spelist }} </v-col>
                <v-col cols="12" sm="2" class="text-right"
                  ><v-btn
                    class="client"
                    @click="addSpecializationItems(spelist)"
                  >
                    <i
                      class="mdi mdi-plus"
                      v-if="!specializationItems.includes(spelist)"
                    ></i>
                    <i
                      class="mdi mdi-minus"
                      v-if="specializationItems.includes(spelist)"
                    ></i></v-btn
                ></v-col>
                <hr class="ml-3 line-width" />
              </v-row>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0 pl-0"> </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 ml-4 bt">
          <v-row>
            <v-col class="pt-0"><h4>Specialazations</h4></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <strong>{{ specializationItems.length }}</strong> Specialazations
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <span
                :class="specializationTotalPercentage > 100 ? 'redclass' : ''"
                >Total = {{ specializationTotalPercentage }}%</span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="
              specializationTotalPercentage > 0 &&
              specializationTotalPercentage < 100
            "
          >
            <v-col cols="12">
              <span class="redclass">The specialization Must Be 100%</span>
            </v-col>
          </v-row>
          <v-row v-if="specializationTotalPercentage > 100">
            <v-col cols="12">
              <span class="redclass"
                >The specialization won't be greater than 100%</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b-progress class="mt-2" max="100" show-value>
                <b-progress-bar
                  :value="specializationTotalPercentage"
                  :variant="
                    specializationTotalPercentage == 100
                      ? 'success'
                      : specializationTotalPercentage < 100
                      ? 'warning '
                      : 'danger '
                  "
                ></b-progress-bar>
              </b-progress>
            </v-col>
          </v-row>
          <v-row>
            <div class="pt-4" v-if="specializationItems.length > 0">
              <v-row v-for="site in specializationItems" :key="site">
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="specialization[site]"
                    hide-details
                    @change="getSpecializationPercentage(site)"
                    outlined
                    dense
                    label=""
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" class="pl-0 pt-4 pr-0"> % </v-col>
                <v-col cols="12" sm="8" class="pl-0">
                  {{ site }}
                </v-col>
              </v-row>
            </div>
          </v-row>
          <v-row> </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: [
    "companyServiceLine",
    "companySpecialization",
    "companyClientSize",
    "companyIndustryFocus",
    "companydata",
  ],
  name: "ComoanyStep4",

  data: function () {
    return {
      classname: "",
      isActive: false,
      isHidden: false,
      isHidden1: false,
      country: null,
      city: [],
      industryFocus: [],
      category: [],

      zipcode: null,
      address: null,
      phone_number: null,
      valid: false,
      employee_count: "",
      hourly_rate: "",
      service_line: [],
      specialization: [],
      specializationList: [],
      clientlist: [],
      focuslist: [],
      catlist: {},
      shows: null,
      shows1: null,
      clientItems: [],
      industryFocusItems: [],
      CategoryItems: [],
      categoryTotalPercentage: 0,
      clientTotalPercentage: 0,
      industryFocusTotalPercentage: 0,
      specializationItems: [],
      specializationTotalPercentage: 0,
      loading: false,
      finalspecialization: {},
      categoryArray: [],
    };
  },

  mounted() {
    // Call function when page load

    this.fetchdata();

    setTimeout(() => {
      if (this.companyServiceLine) {
        console.log(this.companyServiceLine)
        let categoryName = [];
        let $this = this;
        this.categoryTotalPercentage = 0;
        this.companyServiceLine.forEach((element) => {
          categoryName.push(element.name);
          this.categoryTotalPercentage += parseInt(element.percentage);
          $this.category[element.name] = parseInt(element.percentage);
        });
        this.categoryArray = this.CategoryItems = categoryName;
        this.$emit("info", {
          type: "focus_info_category",
          categoryTotalPercentage: this.categoryTotalPercentage,
        });
      }
      if (this.companyIndustryFocus) {
        let categoryName = [];
        let $this = this;
        this.industryFocusTotalPercentage = 0;
        this.companyIndustryFocus.forEach((element) => {
          categoryName.push(element.name);
          this.industryFocusTotalPercentage += parseInt(element.percentage);
          $this.industryFocus[element.name] = parseInt(element.percentage);
        });
        this.industryFocusItems = categoryName;

        this.$emit("info", {
          type: "focus_info_industryFocus",
          industryFocusTotalPercentage: this.industryFocusTotalPercentage,
        });
      }
      if (this.companyClientSize) {
        
        let categoryName = [];
        let $this = this;
        this.clientTotalPercentage = 0;
        this.companyClientSize.forEach((element) => {
          categoryName.push(element.name);
          this.clientTotalPercentage += parseInt(element.percentage);
          $this.city[element.name] = parseInt(element.percentage);
        });
        this.clientItems = categoryName;

        this.$emit("info", {
          type: "focus_info_clientsize",
          clientTotalPercentage: this.clientTotalPercentage,
        });
      }
      if (this.companySpecialization) {
        let categoryName = [];
        let $this = this;
        this.specializationTotalPercentage = 0;
        this.companySpecialization.forEach((element) => {
          categoryName.push(element.name);
          this.specializationTotalPercentage += parseInt(element.percentage);
          $this.specialization[element.name] = parseInt(element.percentage);
        });
        this.specializationItems = categoryName;

        this.$emit("info", {
          type: "focus_info_specialization",
          specializationTotalPercentage: this.specializationTotalPercentage,
        });
      }
    }, 5000);
  },
  methods: {
    ...mapActions([
      "getSpecialization",
      "getClientList",
      "getIndustryList",
      "getCompanyCategory",
    ]),
    getshow(index) {
      this.shows = index;
      this.isHidden = !this.isHidden;
    },
    getshow1(index1) {
      this.shows1 = index1;
      this.isHidden1 = !this.isHidden1;
    },
    getPercentage(val) {
      this.clientTotalPercentage = 0;
      let specArray = [];
      this.clientlist.forEach((res) => {
        if (val == res && this.city[res]) {
          this.clientTotalPercentage =
            parseInt(this.clientTotalPercentage) + parseInt(this.city[res]);
          specArray.push({
            company_id: this.companydata.id,
            client_size_id: val,
            percentage: parseInt(this.city[res]),
          });
        } else {
          if (this.city[res]) {
            this.clientTotalPercentage =
              parseInt(this.clientTotalPercentage) + parseInt(this.city[res]);
            specArray.push({
              company_id: this.$store.state.user.company_slug.id,
              client_size_id: res,
              percentage: parseInt(this.city[res]),
            });
          }
        }
      });
      this.$emit("info", {
        data: { clientsize: specArray },
        type: "focus_info_clientsize",
        clientTotalPercentage: this.clientTotalPercentage,
      });
    },
    getCategoryPercentage(val) {
      this.categoryTotalPercentage = 0;
      this.categoryArray = [];
      Object.keys(this.catlist).forEach((element) => {
        this.categoryArray.push(element);
        if (this.catlist[element]) {
          this.categoryArray.push(Object.keys(this.catlist[element]));
          if (this.catlist[element][Object.keys(this.catlist[element])]) {
            Object.keys(
              this.catlist[element][Object.keys(this.catlist[element])]
            ).forEach((data) => {
              this.categoryArray.push(data);
            });
          }
        }
      });
      let specArray = [];
      this.categoryArray.forEach((res) => {
        if (val == res && this.category[res]) {
          this.categoryTotalPercentage =
            parseInt(this.categoryTotalPercentage) +
            parseInt(this.category[res]);
          specArray.push({
            company_id: this.companydata.id,
            category_id: val,
            percentage: parseInt(this.category[res]),
          });
        } else {
          if (this.category[res]) {
           
            this.categoryTotalPercentage =
              parseInt(this.categoryTotalPercentage) +
              parseInt(this.category[res]);
            specArray.push({
              company_id: this.companydata.id,
              category_id: res,
              percentage: parseInt(this.category[res]),
            });
          }
        }
      });
      this.$emit("info", {
        data: { category: specArray },
        type: "focus_info_category",

        categoryTotalPercentage: this.categoryTotalPercentage,
      });
    },

    getSpecializationPercentage(val) {
      let specArray = [];
      this.specializationTotalPercentage = 0;
      this.specializationList.forEach((res) => {
        if (val == res && this.specialization[res]) {
          this.specializationTotalPercentage =
            parseInt(this.specializationTotalPercentage) +
            parseInt(this.specialization[res]);
          specArray.push({
           company_id: this.companydata.id,
            specializations_id: val,
            percentage: parseInt(this.specialization[res]),
          });
        } else {
          if (this.specialization[res]) {
            this.specializationTotalPercentage =
              parseInt(this.specializationTotalPercentage) +
              parseInt(this.specialization[res]);
            specArray.push({
            company_id: this.companydata.id,
              specializations_id: res,
              percentage: parseInt(this.specialization[res]),
            });
          }
        }
      });
      this.$emit("info", {
        data: { specialization: specArray },
        type: "focus_info_specialization",
        specializationTotalPercentage: this.specializationTotalPercentage,
      });
    },
    getIndustryFocusPercentage(val) {
      this.industryFocusTotalPercentage = 0;
      let specArray = [];
      this.focuslist.forEach((res) => {
        if (val == res && this.industryFocus[res]) {
          this.industryFocusTotalPercentage =
            parseInt(this.industryFocusTotalPercentage) +
            parseInt(this.industryFocus[res]);
          specArray.push({
           company_id: this.companydata.id,
            industry_focus_id: val,
            percentage: parseInt(this.industryFocus[res]),
          });
        } else {
          if (this.industryFocus[res]) {
            this.industryFocusTotalPercentage =
              parseInt(this.industryFocusTotalPercentage) +
              parseInt(this.industryFocus[res]);
            specArray.push({
              company_id: this.companydata.id,
              industry_focus_id: res,
              percentage: parseInt(this.industryFocus[res]),
            });
          }
        }
      });
      this.$emit("info", {
        data: { industryFocus: specArray },
        type: "focus_info_industryFocus",
        industryFocusTotalPercentage: this.industryFocusTotalPercentage,
      });
    },
    fetchdata() {
      this.loading = true;
      this.getSpecialization()
        .then((resp) => {
          this.loading = true;
          resp.data.data.forEach((res) => {
            this.specializationList.push(res.name);
          });
        })
        .finally(() => (this.loading = false));
      this.getClientList()
        .then((resp) => {
          this.loading = true;
          resp.data.data.forEach((res) => {
            this.clientlist.push(res.name);
          });
        })
        .finally(() => (this.loading = false));
      this.getIndustryList()
        .then((resp) => {
          this.loading = true;
          resp.data.data.forEach((res) => {
            this.focuslist.push(res.name);
          });
        })
        .finally(() => (this.loading = false));

      this.getCompanyCategory()
        .then((response) => {
          this.loading = true;
          let main_cat = {};
          response.data.data.forEach((value) => {
            if (value["parent_id"] == 0) {
              let sub_cat = {};
              let name = value["name"];
              let id = value["id"];
              response.data.data.forEach((value1) => {
                if (value1["parent_id"] == id) {
                  let sub_sub_cat = {};
                  let name1 = value1["name"];
                  let id1 = value1["id"];

                  response.data.data.forEach((value2) => {
                    if (value2["parent_id"] == id1) {
                      let name2 = value2["name"];
                      let id2 = value2["id"];
                      let sub_sub_sub_cat = {};
                      response.data.data.forEach((value3) => {
                        if (value3["parent_id"] == id2) {
                          let name3 = value3["name"];
                          let id3 = value3["id"];
                          let sub_sub_sub_sub_cat = [];
                          response.data.data.forEach((value4) => {
                            if (value4["parent_id"] == id3) {
                              sub_sub_sub_sub_cat.push(value4["name"]);
                            }
                          });

                          sub_sub_sub_cat[name3] = sub_sub_sub_sub_cat;
                        }
                      });

                      sub_sub_cat[name2] = sub_sub_sub_cat;
                    }
                  });

                  sub_cat[name1] = sub_sub_cat;
                  this.catlist[name1] = sub_sub_cat;
                }
              });
              main_cat[name] = sub_cat;
            }
          });
        })
        .finally(() => (this.loading = false));
    },
    addSpecializationItems(id) {
      if (this.specializationItems.indexOf(id) != -1) {
        this.specializationTotalPercentage = 0;
        this.specialization[id] = "";
        this.specializationList.forEach((res) => {
          if (this.specialization[res]) {
            this.specializationTotalPercentage =
              parseInt(this.specializationTotalPercentage) +
              parseInt(this.specialization[res]);
          }
        });
        this.specializationItems.splice(this.specializationItems.indexOf(id));
      } else {
        this.specializationItems.push(id);
      }
    },
    addIndustryFocusItems(id) {
      if (this.industryFocusItems.indexOf(id) != -1) {
        this.industryFocusTotalPercentage = 0;
        this.industryFocus[id] = "";
        this.focuslist.forEach((res) => {
          if (this.industryFocus[res]) {
            this.industryFocusTotalPercentage =
              parseInt(this.industryFocusTotalPercentage) +
              parseInt(this.industryFocus[res]);
          }
        });
        this.industryFocusItems.splice(this.industryFocusItems.indexOf(id), 1);
      } else {
        this.industryFocusItems.push(id);
      }
    },
    addCategoryItems(id) {
      if (this.CategoryItems.indexOf(id) != -1) {
        this.categoryTotalPercentage = 0;
        this.category[id] = "";
        this.categoryArray.forEach((res) => {
          if (this.category[res]) {
            this.categoryTotalPercentage =
              parseInt(this.categoryTotalPercentage) +
              parseInt(this.category[res]);
          }
        });
        this.CategoryItems.splice(this.CategoryItems.indexOf(id), 1);
      } else {
        this.CategoryItems.push(id);
      }
    },

    addItems(id) {
      if (this.clientItems.indexOf(id) != -1) {
        this.clientTotalPercentage = 0;
        this.city[id] = "";
        this.clientlist.forEach((res) => {
          if (this.city[res]) {
            this.clientTotalPercentage =
              parseInt(this.clientTotalPercentage) + parseInt(this.city[res]);
          }
        });
        this.clientItems.splice(this.clientItems.indexOf(id), 1);
      } else {
        this.clientItems.push(id);
      }
    },
  },
};
</script>
<style>
.redclass {
  color: red;
}
.vertical-line {
  border-right: 1px solid !important;
  height: 50px;
}
.line-width {
  max-width: 94% !important;
}
.focas-side1 {
  border: 1px solid !important;
  box-shadow: 5px 10px 18px #769ff8;
}
.v-progress-linear {
  height: 30px !important;
}
.hr-height {
  margin-bottom: 60% !important;
}
.service {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.client,
.client:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
